import React, { memo, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { useTranslation } from '@pancakeswap/localization'
import { useMenuItems } from './hooks/useMenuItems'
import MenuTwo from './MenuTwo'

const MobileNavSwapper = styled.div`
  position: fixed;
  top: 56px;
  right: 0;
  z-index: 9;
  width: 100vw;
  height: calc(100vh - 56px);
  .logo {
    display: flex;
    align-items: center;
    margin-right: 10px;
    img {
      width: 40px;
    }
  }
  .logo1 {
    display: flex;
    align-items: center;

    img {
      width: 7px;
      height: 10px;
    }
  }
  .logo2 {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    background: #1a1a1a;
  }
  .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
    color: #ffffff;
    font-weight: 600;
    line-height: 1.5;
    margin-left: 0.7rem;
    font-size: 14px;
  }
  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    padding-left: 0px;
    background-color: #000;

    .ant-menu-title-content {
      margin-left: 30px;
    }
  }
`
const OverSwapper = styled.div`
  width: 30%;
  height: 100%;
  background: rgba(97, 96, 101, 0.5);
`

const Nav = styled.div`
  width: 70%;
  position: absolute;
  right: 0;
  top: 0;
  background: #1a1a1a;
  height: 100%;
  padding-top: 1rem;
`

const NavItem = styled.div`
  display: flex;
  padding: 1vh 0 1vh 24px;
  justify-content: space-between;
  align-items: center;
  .pagename {
    flex: 1;
  }
  &:hover {
    background: rgba(253, 236, 242, 0.5);
  }
  button {
    padding: 0;
  }
  svg {
    margin-right: 0;
  }
`
const LogSwapper = styled.div`
  position: absolute;
  bottom: 2rem;
  padding-left: 1rem;
  .imageSwapper {
    display: flex;
    margin-bottom: 1rem;
  }
  .ggg {
    img {
      width: 80px;
      height: 30px;
    }
  }
`
interface PropsType {
  callBack: () => void
  currentLang: any
  langs: any
  setLang: any
}

function MobileNav(props: PropsType) {
  const menuItems = useMenuItems()

  const { t } = useTranslation()
  const router = useRouter()
  const { callBack, currentLang, langs, setLang } = props
  const [currentPage, setCurrentPage] = useState('')

  useEffect(() => {
    if (router.pathname === currentPage) callBack()
  }, [router])

  const itemClick = (url: string) => {
    router.push(url)
    setCurrentPage(url)
  }
  const overClick = () => {
    callBack()
  }

  return (
    <MobileNavSwapper>
      <OverSwapper onClick={overClick} />
      <Nav>
        {/* {menuItems.map((item) => (
          <NavItem onClick={() => itemClick(item.href)} key={item.href}>
            <div className="logo2">
              <img src={`/images/rightnav/${item.icon}.png`} alt=""  loading='lazy' />
            </div>
            <Text className="pagename" ml=".7rem" fontSize="14px" bold>
              {t(item.label)}
            </Text>
            <div className="logo1">
              <img src={`/images/rightnav/right1.png`} alt=""  loading='lazy' />
            </div>
          </NavItem>
        ))} */}
        <MenuTwo callBack={overClick} />
        {/* <NavItem>
          <Flex alignItems="center">
            <Box mt="4px">
              <LangSelector
                currentLang={currentLang}
                langs={langs}
                setLang={setLang}
                buttonScale="xs"
                color="textSubtle"
                hideLanguage
              />
            </Box>
          </Flex>
          <div className="logo1">
            <img src={`/images/rightnav/right1.png`} alt=""  loading='lazy' />
          </div>
        </NavItem> */}

        <LogSwapper>
          <div className="imageSwapper">
            <div className="logo ggg">
              <img src="/images/rightnav/logo.png" alt="https://t.me/+WYb5xrv-IgQyYWE1" loading="lazy" />
            </div>
            <div className="logo">
              <a href="https://t.me/+WYb5xrv-IgQyYWE1" target="_blank" rel="noopener noreferrer nofollow">
                <img src={`/images/rightnav/telegram.png`} alt="https://t.me/+WYb5xrv-IgQyYWE1" loading="lazy" />
              </a>
              <a href="https://t.me/GToken_EN" target="_blank" rel="noopener noreferrer nofollow">
                <img src={`/images/rightnav/telegram.png`} alt="https://t.me/GToken_EN" loading="lazy" />
              </a>
              <a href="https://x.com/gtokentool" target="_blank" rel="noopener noreferrer nofollow">
                <img src={`/images/rightnav/x.png`} alt="https://x.com/gtokentool" loading="lazy" />
              </a>
            </div>
          </div>
        </LogSwapper>
      </Nav>
    </MobileNavSwapper>
  )
}

export default memo(MobileNav)
