import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
    // font-family: Comic Sans MS, Comic Sans, cursive;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
  .ant-modal-close {
    color: #fb512d;
  }
  .ant-picker-input > input {
    color: #E5E5E5 !important;
  }
  .ant-select-dropdown {
    background-color: #1F1F1F !important;  
    border-radius: 5px;
border: 1px solid #474747;
  }
  .ant-select-item {
    color: #E5E5E5 !important;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #303030 !important;  
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #303030 !important;  
  }
  .notification {
    min-width: 80px;;
    min-height:60px;
    border-radius: 5px;
    font-weight: bold;
    padding: 0 0 0 16px;
    overflow: revert;
    top:6rem !important;
    cursor: pointer;


    .ant-notification-notice-message {
      color: #280d5f;
    }
    .ant-notification-notice-close {
      color: #1fc7d4;
    }
    .anticon.ant-notification-notice-icon-info,
    .anticon.ant-notification-notice-icon-success,
    .anticon.ant-notification-notice-icon-error,
    .anticon.ant-notification-notice-icon-warning{
      color: #fff;
      padding-top: 1rem;
    }
    .ant-notification-notice-message {
      min-height:60px;
      background: #fff;
      border-top-right-radius: 16px;
      display: flex;
      align-items: center;
      padding:.5rem 2.5rem .5rem .5rem;
    }
    .ant-notification-notice-closable 
    .ant-notification-notice-message {
      padding-right: 2.5rem;
      margin-bottom: 0;
    }
    .ant-notification-notice-description {
      background: #fff;
      border-bottom-right-radius: 16px;
      padding: 0.5rem 2.5rem 0.5rem 0.5rem;
    }
    .ant-notification-notice-with-icon .ant-notification-notice-message{
      margin-bottom:0;
    }
  }

  .relative {
    position: relative;
  }
  .flexsbc {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .addBtn {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
  }
  .ant-btn-primary {
    background: #593b6a7a;
    border-color: #593b6a7a;
  }


.ant-modal-body {
  /* background: #1a1a1a; */
}
  /* .ant-picker {
    width: 37vw;
  } */

  .ant-picker {
    background: #1a1a1a;
    /* border: none !important; */
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #474747;
    &:focus-within {
      box-shadow: none;
    }
    svg {
      fill: #fff;
    }
  }
`

export default GlobalStyle
