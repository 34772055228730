import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface TokenType {
  address: string
  name: string
  symbol: string
  decimals: number
  totalSupply: number
  balanceOf: number
}

const initialState = {
  senderTokenConfig: [], // 发送config
  senderToken: {
    address: '',
    name: '',
    symbol: '',
    decimals: 0,
    totalSupply: 0,
    balanceOf: 0,
  },

  miningConfig: {
    tokenAddress_: '',
    startTime_: '',
    liftedTime_: '',
    endTime_: '',
    pledgeMax_: '',
    time_: '',
    lockTime_: '',
    invite_: '',
    contractAddress_: '',
    secAward_: '',
    totalAward_: '',
    logoUrl_: '',
    website_: '',
    faceBook_: '',
    twitter_: '',
    github_: '',
    telegram_: '',
    instagram_: '',
    discord_: '',
    reddit_: '',
    description_: '',
    miningName_: '',
    miningTokenSymbol_: '',
    miningDecimals_: '',
    miningTotalSupply_: '',
    miningBalanceOf_: '',
    awardTokenSymbol_: '',
    awardName_: '',
    awardDecimals_: '',
    awardTotalSupply_: '',
    awardBalanceOf_: '',
  },
}

export const counterSlice = createSlice({
  name: 'yagong',
  initialState,
  reducers: {
    senderTokenConfigChange(state, action) {
      state.senderTokenConfig = action.payload
    },
    senderTokenChange(state, action) {
      state.senderToken = action.payload
    },
    miningConfigChange(state, action) {
      state.miningConfig = action.payload
    },
  },
})

export const { senderTokenConfigChange, senderTokenChange, miningConfigChange } = counterSlice.actions

export const senderTokenConfig = (state: { yagong: { senderTokenConfig: any } }) => state.yagong.senderTokenConfig
export const senderToken = (state: { yagong: { senderToken: TokenType } }) => state.yagong.senderToken

export const miningConfig = (state) => state.yagong.miningConfig

export default counterSlice.reducer
