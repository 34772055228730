import {
  copyToClipboard,
  copyToClipboard1,
  shareToFacebook,
  shareToReddit,
  shareToTelegram,
  shareToTwitter,
  shareToWhatsApp,
} from './func'
import { Content, ContentPage, SharePage, Title } from './style'

const Share = ({ onClose }) => {
  const text =
    'G TOKEN is the most comprehensive one-click currency issuance tool. It is a professional currency issuance platform with 2 years of experience. It has an active official community and dedicated customer service.'
  const url = window.location.href
  return (
    <SharePage>
      <ContentPage>
        <Title>
          <span>分享</span>
          <div style={{ cursor: 'pointer' }} onClick={onClose}>
            <img src="/images/close.webp" alt="close" />
          </div>
        </Title>
        <Content>
          <div className="list" onClick={() => shareToTwitter({ text, url: '869.one', extraText: 'via @GTokenTool' })}>
            <div className="Img">
              <img src="/images/Twitter.webp" alt="Twitter" />
            </div>
            <p>Twitter</p>
          </div>
          <div className="list" onClick={() => shareToFacebook({ url })}>
            <div className="Img">
              <img src="/images/Facebook.webp" alt="Facebook" />
            </div>
            <p>Facebook</p>
          </div>
          <div className="list" onClick={() => shareToTelegram({ text, url })}>
            <div className="Img">
              <img src="/images/Telegram.webp" alt="Telegram" />
            </div>
            <p>Telegram</p>
          </div>
          <div className="list" onClick={() => shareToReddit({ text, url })}>
            <div className="Img">
              <img src="/images/Reddit.webp" alt="Reddit" />
            </div>
            <p>Reddit</p>
          </div>
          <div className="list" onClick={() => shareToWhatsApp({ text, url, extraText: '111' })}>
            <div className="Img">
              <img src="/images/Whatsapp.webp" alt="Whatsapp" />
            </div>
            <p>Whatsapp</p>
          </div>
          <div className="list" onClick={copyToClipboard}>
            <div className="Img">
              <img src="/images/copy.webp" alt="copy" />
            </div>
            <p>复制链接</p>
          </div>
          <div className="list" onClick={() => copyToClipboard1({ url })}>
            <div className="Img">
              <img src="/images/Iframe.webp" alt="Iframe" />
            </div>
            <p>复制iframe嵌套HTML代码</p>
          </div>
        </Content>
      </ContentPage>
    </SharePage>
  )
}

export default Share
