import { message } from 'antd'

export const shareToReddit = ({ text, url }) => {
  const redditUrl = `https://www.reddit.com/submit?url=${encodeURIComponent(url)}&title=${encodeURIComponent(text)}`
  window.open(redditUrl, '_blank')
}
export const copyToClipboard1 = ({ url }) => {
  // const currentUrl = window.location.href;
  const iframeHtml = `<iframe src="${url}" width="600" height="400" title="Current Page iFrame" style="border:1px solid #000;"></iframe>`

  navigator.clipboard.writeText(iframeHtml)
  message.success('复制成功')

  // .then(() => {
  //   setCopied(true);
  //   setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  // });
}

export const shareToTelegram = ({ text, url }) => {
  const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`
  window.open(telegramUrl, '_blank')
}
export const shareToTwitter = ({ text, url, extraText }) => {
  const finalUrl = `${url}${extraText ? ` ${extraText}` : ''}`
  const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(
    finalUrl,
  )}`
  window.open(twitterUrl, '_blank')
}

export const shareToWhatsApp = ({ text, url, extraText }) => {
  const finalUrl = `${url}${extraText ? ` ${extraText}` : ''}`
  const message = `${text} ${finalUrl}`
  const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`
  window.open(whatsappUrl, '_blank')
}

export const copyToClipboard = () => {
  const currentUrl = window.location.href
  navigator.clipboard
    .writeText(currentUrl)
    .then(() => {
      message.success('复制成功')
    })
    .catch((err) => {
      message.error('复制失败')

      console.error('复制失败', err)
    })
  // .then(() => {
  //     setCopied(true);
  //     setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  // });
}
export const shareToFacebook = ({ url }) => {
  console.log(url, 'url')
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`
  window.open(facebookUrl, '_blank')
}
