import styled from 'styled-components'

export const SharePage = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: ;
  left: 0;
  z-index: 99999;
`
export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .list {
    cursor: pointer;
    width: 25%;
    text-align: center;
    margin-top: 30px;
    .Img {
      width: 43px;
      aspect-ratio: 43/40;
      margin: 0 auto;
    }
    p {
      margin-top: 10px;
      font-size: 14px;
    }
  }
  .list:last-child {
    width: 50%;
  }
`
export const Title = styled.div`
  display: flex;
  justify-content: space-between;
`
export const ContentPage = styled.div`
  width: 354px;
  height: 267px;
  background: #1a1a1a;
  border-radius: 3px;

  margin-top: 250px;
  margin-left: calc(50% - 354px / 2);
  color: #fff;
  padding: 16px;
  @media (max-width: 768px) {
    margin-top: 180px;
  }
`
