import React, { useState, useMemo } from 'react'
import type { MenuProps } from 'antd'
import { Menu } from 'antd'
import { useRouter } from 'next/router'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@pancakeswap/localization'

type MenuItem = Required<MenuProps>['items'][number]

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem
}

interface LevelKeysProps {
  key?: string
  children?: LevelKeysProps[]
}

interface PropsType {
  callBack: () => void
}

const App = (props: PropsType) => {
  const { callBack } = props
  const {
    t,
    currentLanguage: { code: languageCode },
  } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const router = useRouter()

  const items: MenuItem[] = [
    getItem(
      t('Launchpads'),
      '5',
      <div className="logo2">
        <img src={`/images/rightnav/firing.png`} alt="fairlaunch" loading="lazy" width={20} height={20} />
      </div>,
      [
        getItem(t('Fairlaunch List'), 'fairlaunch'),
        getItem(t('Pre-sale list'), 'launchpad'),
        getItem(t('Create Fairlaunch'), 'fairlaunch/create'),
        getItem(t('Create a normal pre-sale'), 'launchpad/create'),
      ],
    ),
    // getItem(
    //   t('Guess'),
    //   '0',
    //   <div className="logo2">
    //     <img src={`/images/rightnav/guess.png`} alt=""  loading='lazy' width={20} height={20} />
    //   </div>,
    //   [getItem(t('Guess List'), 'guessList'), getItem(t('Create Guess'), 'guess')],
    // ),
    getItem(
      t('Create Token'),
      '1',
      <div className="logo2">
        <img src={`/images/rightnav/token.png`} alt="tokenfactory" loading="lazy" width={20} height={20} />
      </div>,
      [
        getItem(t('Create Token'), 'tokenfactory'),
        getItem(`${t('Create')}NFT`, 'createnft'),
        getItem(t('Manage Tokens'), 'managetokens'),
        getItem(t('Token Tool'), 'tool'),
      ],
    ),
    getItem(
      t('Tools'),
      '8',
      <div className="logo2">
        <img src={`/images/rightnav/batch.png`} alt="sendertoken" loading="lazy" width={20} height={20} />
      </div>,
      [
        getItem(t('Send tokens'), 'sendertoken'),
        getItem(t('Send NFT In Batches'), 'sendernft'),
        getItem(t('Add Currency Address'), 'increaseAddr'),
      ],
    ),
    getItem(
      t('Runes'),
      '4',
      <div className="logo2">
        <img src={`/images/rightnav/ido.png`} alt="fwlist" loading="lazy" width={20} height={20} />
      </div>,
      [
        getItem(t('Rune list'), 'fwlist'),
        getItem(t('Create runes'), 'createInscription'),
        getItem(t('Management Runes'), 'createInscription/list'),
        getItem(t('Batch Inscription'), 'inscription'),
      ],
    ),
    getItem(
      t('Trade'),
      '6',
      <div className="logo2">
        <img src={`/images/rightnav/trade.png`} alt="liquidity" loading="lazy" width={20} height={20} />
      </div>,
      [getItem(t('Swap'), 'swap'), getItem(t('Liquidity'), 'liquidity')],
    ),
    getItem(
      t('Create IDO'),
      '2',
      <div className="logo2">
        <img src={`/images/rightnav/createido.png`} alt="Create IDO" loading="lazy" width={20} height={20} />
      </div>,
      [getItem(t('Create IDO'), 'idov2'), getItem(t('IDO Management'), 'idov2/list')],
    ),
    getItem(
      t('Create Mining'),
      '3',
      <div className="logo2">
        <img src={`/images/rightnav/wa.png`} alt="mining" loading="lazy" width={20} height={20} />
      </div>,
      [
        getItem(t('Create Mining'), 'mining'),
        getItem(t('NFT casting'), 'tokencreatenft'),
        getItem(t('project list'), 'tokencreatenft/list'),
      ],
    ),

    getItem(
      t('Market Value'),
      '7',
      <div className="logo2">
        <img src={`/images/rightnav/div.png`} alt="Market Bot" loading="lazy" width={20} height={20} />
      </div>,
      [getItem(t('Market Bot'), 'bot'), getItem(t('Bot'), 'https://robot-nuu.vercel.app')],
    ),

    getItem(
      t('G Lock'),
      '10',
      <div className="logo2">
        <img src={`/images/rightnav/createlock.png`} alt="createlock" loading="lazy" width={20} height={20} />
      </div>,
      [getItem(t('Create Lock'), 'createlock'), getItem(t('Token Lock'), 'tokenlock'), getItem(t('LP Lock'), 'lplock')],
    ),

    getItem(
      t('Docs'),
      '9',
      <div className="logo2">
        <img src={`/images/rightnav/boss.png`} alt="uploadImage" loading="lazy" width={20} height={20} />
      </div>,
      [
        getItem(t('Bulk Upload Images'), 'uploadImage'),
        getItem(t('Contract Number Tool'), 'liang'),
        getItem(t('Docs'), 'https://docs.ggg.dog'),
      ],
    ),
    getItem(
      t('News'),
      '/news',
      <div className="logo2">
        <img src={`/images/rightnav/news.webp`} alt="uploadImage" loading="lazy" width={20} height={20} />
      </div>,
    ),
  ]

  const getLevelKeys = (items1: LevelKeysProps[]) => {
    const key: Record<string, number> = {}
    const func = (items2: LevelKeysProps[], level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level
        }
        if (item.children) {
          return func(item.children, level + 1)
        }
      })
    }
    func(items1)
    return key
  }
  const levelKeys = getLevelKeys(items as LevelKeysProps[])

  const [stateOpenKeys, setStateOpenKeys] = useState([])

  const onOpenChange: MenuProps['onOpenChange'] = (openKeys) => {
    const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1)
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey])

      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      )
    } else {
      // close
      setStateOpenKeys(openKeys)
    }
  }

  const menuClick = (e) => {
    const origin = window.location.origin
    if (!e.key.startsWith('http')) {
      router.push(`${origin}/${e.key}`)
    } else {
      router.push(`${e.key}`)
    }
    callBack()
  }

  return (
    <Menu
      mode="inline"
      theme="dark"
      openKeys={stateOpenKeys}
      onOpenChange={onOpenChange}
      style={{ width: '100%', height: '70vh', overflow: 'scroll' }}
      items={items}
      onClick={menuClick}
    />
  )
}

export default App
