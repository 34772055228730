import memoize from 'lodash/memoize'
import { ContextApi } from '@pancakeswap/localization'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'G TOKEN一键发币平台',
  // description:
  //   'The most popular AMM on BSC by user count! Earn CAKE through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by PancakeSwap), NFTs, and more, on a platform you can trust.',
  // image: 'https://pancakeswap.finance/images/hero.png',
}

interface PathList {
  paths: { [path: string]: { title: string; basePath?: boolean; description?: string } }
  defaultTitleSuffix: string
}

const getPathList = (t: ContextApi['t']): PathList => {
  return {
    paths: {
      '/': { title: t('Home') },
      '/swap': { basePath: true, title: t('Exchange') },
      '/limit-orders': { basePath: true, title: t('Limit Orders') },
      '/add': { basePath: true, title: t('Add Liquidity') },
      '/remove': { basePath: true, title: t('Remove Liquidity') },
      '/liquidity': { title: t('Liquidity') },
      '/find': { title: t('Import Pool') },
      '/competition': { title: t('Trading Battle') },
      '/prediction': { title: t('Prediction') },
      '/prediction/leaderboard': { title: t('Leaderboard') },
      '/farms': { title: t('Farms') },
      '/farms/auction': { title: t('Farm Auctions') },
      '/pools': { title: t('Pools') },
      '/lottery': { title: t('Lottery') },
      '/ifo': { title: t('Initial Farm Offering') },
      '/teams': { basePath: true, title: t('Leaderboard') },
      '/voting': { basePath: true, title: t('Voting') },
      '/voting/proposal': { title: t('Proposals') },
      '/voting/proposal/create': { title: t('Make a Proposal') },
      '/info': { title: t('Overview'), description: 'View statistics for Pancakeswap exchanges.' },
      '/info/pools': { title: t('Pools'), description: 'View statistics for Pancakeswap exchanges.' },
      '/info/tokens': { title: t('Tokens'), description: 'View statistics for Pancakeswap exchanges.' },
      '/nfts/collections': { basePath: true, title: t('Collections') },
      '/nfts/activity': { title: t('Activity') },
      '/profile': { basePath: true, title: t('Profile') },
      '/tokenfactory': { title: t('Create Token') },
      '/createnft': { title: t('Create NFT') },
      '/managetokens': { title: t('Manage Tokens') },
      '/tool': { title: t('Token Tool') },
      '/sendertoken': { title: t('Send tokens') },
      '/sendernft': { title: t('Send NFT In Batches') },
      '/increaseAddr': { title: t('Add Currency Address') },
      '/fwlist': { title: t('Rune list') },
      '/createInscription': { title: t('Create runes') },
      '/createInscription/list': { title: t('Management Runes') },
      '/inscription': { title: t('Batch Inscription') },
      '/idov2': { title: t('Create IDO') },
      '/idov2/list': { title: t('IDO Management') },
      '/mining': { title: t('Create Mining') },
      '/tokencreatenft': { title: t('NFT casting') },
      '/tokencreatenft/list': { title: t('Mining list') },
      '/bot': { title: t('Market Bot') },
      '/createlock': { title: t('Create Lock') },
      '/tokenlock': { title: t('Token Lock') },
      '/lplock': { title: t('LP Lock') },
      '/uploadImage': { title: t('Bulk Upload Images') },
      '/liang': { title: t('Contract Number Tool') },
      '/fairlaunch': { title: t('Fairlaunch List') },
      '/launchpad': { title: t('Pre-sale list') },
      '/fairlaunch/create': { title: t('Create Fairlaunch') },
      '/launchpad/create': { title: t('Create Presale') },
      '/tokencreatenft/list/info': { title: '挖矿详情页' },
      // "/increaseAddr":{title:t("Add")},
    },
    defaultTitleSuffix: 'G TOKEN一键发币平台',
  }
}

export const getCustomMeta = memoize(
  (path: string, t: ContextApi['t'], _: string): PageMeta => {
    const pathList = getPathList(t)
    const pathMetadata =
      pathList.paths[path] ??
      pathList.paths[Object.entries(pathList.paths).find(([url, data]) => data.basePath && path.startsWith(url))?.[0]]

    if (pathMetadata) {
      return {
        title: `${pathMetadata.title} | ${t(pathList.defaultTitleSuffix)}`,
        ...(pathMetadata.description && { description: pathMetadata.description }),
      }
    }
    return null
  },
  (path, t, locale) => `${path}#${locale}`,
)
